<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" align="center">
      <router-link :to="{ name: 'medica_add' }">
        <md-button class="md-success">
          <span class="md-label">
            <md-icon class="material-icons">add_box</md-icon>
          </span>
          Agregar
        </md-button>
      </router-link>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Registros</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-bind:value="queriedData" class="table-striped">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Fecha Creación">{{
                item.fecha_inicio + ' ' + item.hora_inicio,
              }}</md-table-cell>
              <md-table-cell md-label="Placa Patente">{{
                item.placa_patente
              }}</md-table-cell>
              <md-table-cell md-label="Unidad Negocio">{{
                item.unidad_negocio
              }}</md-table-cell>
              <md-table-cell md-label="Servicio">{{
                item.estado === 0 ? item.servicio + "*" : item.servicio
              }}</md-table-cell>
              <md-table-cell md-label="Monitor">{{
                item.user_name
              }}</md-table-cell>
              <md-table-cell md-label="Estado">{{
                item.estado === 0 ? "En Proceso" : "Finalizada"
              }}</md-table-cell>
              <md-table-cell md-label="Sincronizado">{{
                item.hasPendingWrites === false
                  ? "SI"
                  : item.hasPendingWrites === true
                  ? "NO"
                  : "-"
              }}</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>create</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
export default {
  components: {
    Pagination,
  },
  computed: {
    queriedData: function() {
      return this.$store.state.m_mediciones_ica.slice(this.from, this.to);
    },
    to: function() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from: function() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total: function() {
      return this.$store.state.m_mediciones_ica.length;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0,
      },
    };
  },
  methods: {
    handleEdit(item) {
      if (item.estado === 1) {
        return this.$notify({
          timeout: 1500,
          message: `Medición no puede ser editada`,
          icon: "add_alert",
          verticalAlign: "top",
          horizontalAlign: "center",
          type: "danger",
        });
      }
      this.$store.commit("initIcaDet", {
        uuid: item.uuid,
        fecha_inicio_timestamp: item.fecha_inicio_timestamp,
        placa_patente: item.placa_patente,
        servicio: item.servicio,
        estandar: item.estandar,
        unidad_negocio: item.unidad_negocio,
        tipo_veh: item.tipo_veh,
      });
      this.$router.push({
        name: "medica_det",
        params: { uuid: item.uuid },
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
